<template>
  <div id="app">
    <redoc-wrapper v-if="url" :spec-or-spec-url="url" :options="redocOptions"></redoc-wrapper>
  </div>
</template>

<script>
import axios from 'axios'
import RedocWrapper from '@hnluu8/vue-redoc-wrapper'

export default {
  name: 'app',
  components: {
    RedocWrapper
  },
  mounted() {
    axios 
      .get('/page/api_doc_token')
      .then(res => {
        let url = window.domain ? window.domain : '';

        this.url = `${url}/swagger-doc-${res.data.api_doc_token}`;
      })
      .catch(err => {
        console.error(err);
      });
  },
  data() {
    return {
      url: null,
      // https://github.com/Redocly/redoc#redoc-options-object
      redocOptions: {
        hideDownloadButton: false      
      }
    }
  }
}
</script>